import React, { useEffect, useRef, useState } from 'react';
import Layout from '../components/layout';
import WebinarRegistrationPopup from '../components/Popup/webinar-registration-popup';
import SEO from "../components/seo";
import IPProtectionImg from '../images/ip-protection-for.png';
import '../styles/webinar-page.css';

const Webinar3Steps = () => {
  const seo = {
    metaDesc: 'Webinar 3-Steps Strategy Guide to Protect Your Software Innovations - Inventiv.org'
  }
  const WEBINAR_3_STEPS = "https://webinarkit.com/webinar/registration/oneclick/675e98b8de9c1309098a8e07?date=jit_15";
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('Technical Support');
  const childRef = useRef();
  const openPopup = () => {
    //
    childRef.current?.open(WEBINAR_3_STEPS);
  }
  //
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <Layout activeMenu={'webinar'}>
      <SEO title="Webinar 3-Steps Strategy Guide to Protect Your Software Innovations - Inventiv.org" canonical='/webinar-3-steps-strategy-guide-to-protect-your-software-innovations' seo={seo} />

      <div class="sub-banner" style={{ height: 'auto', backgroundColor: '#0C6873' }}>
        <div class="container py-5">
          <div class="text-center">
            <h1 className='text-white'>FREE TRAINING FOR STARTUPS AND FOUNDERS</h1>
            <h6 className='text-white'>Presented by Bao Tran, Patent Attorney</h6>
          </div>
        </div>
      </div>

      {/* <!-- Rsvp start --> */}
      <div class="rsvp content-area-2" id="rsvp">
        <div class="container px-5">
          <div className='px-5'>
            <div class="row">
              <div class="col-12 wow fadeInUp delay-04s">
                <div class="row">
                  <h1 className="text-center bold">3-STEPS STRATEGY GUIDE TO PROTECT YOUR SOFTWARE INNOVATIONS</h1>
                  <h5 className='text-center'>Unlock the secret techniques used by top inventors to shield their innovations and maximize profit potential!</h5>
                </div>
              </div>
            </div>
            <div className='my-5'>
              <div className='row'>
                <div class=" text-center col-md-6 wow fadeInUp delay-04s">
                  <div className='circular_image'>
                    <img style={{ display: 'block', objectFit: 'cover' }} src='https://webinarkituploads.s3.us-east-2.amazonaws.com/user%2F6747df2bfba398dfcc5f14e8%2Frohan-makhecha-jw3GOzxiSkw-unsplash.jpg' alt='3-STEPS STRATEGY GUIDE TO PROTECT YOUR SOFTWARE INNOVATIONS'></img>
                  </div>
                  <h5 className='bold mt-2'>Instant watch session available. Join now!</h5>
                </div>
                <div class="col-md-6 wow fadeInUp delay-04s">
                  <div className='my-element'>
                    <h3 className='bold text-center'>Here's what you'll learn:</h3>
                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'>Why patents are an <strong>ASSET</strong>, not just an expense!</h5>
                    </div>

                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'>The shocking truth about how big companies can steal your <strong>ideas</strong></h5>
                    </div>

                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'>Insider tips on navigating the complex <strong>patent application process</strong></h5>
                    </div>

                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'>How to build a bulletproof <strong>patent portfolio</strong></h5>
                    </div>
                    <div>
                      <a href="#" onClick={e => { e.preventDefault(); openPopup()}} class="btn btn-default my-3" style={{ width: '100%' }}>REGISTER NOW</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container px-5'>
        <div class="d-flex justify-content-center">
          <img src={IPProtectionImg} alt='Our lawyers have worked on IP protection for' />
        </div>
      </div>
      <WebinarRegistrationPopup ref={childRef}></WebinarRegistrationPopup>
      {/* <!-- Rsvp end --> */}
    </Layout>
  )
};

export default Webinar3Steps;